let origin = "https://future.zhuneng.cn";
//获取活动列表
const getActivityListUrl = `/activityApi/api/activity/activityList`;
//获取活动详情
const getActivityDetailUrl = `/activityApi/api/activity/activityDetail`;
//新增参与人
const addPeopleUrl = `/activityApi/api/activity/myApplicantsAddOrEdit`;
//我的活动参与人列表
const addPeopleListUrl = `/activityApi/api/activity/myActivityApplicantsList`;
//我的活动参与人详情
const addPeopleDetailUrl = `/activityApi/api/activity/myActivityApplicantsDetail`;
//删除活动参与人
const deletePeopleUrl = `/activityApi/api/activity/myActivityApplicantsDelete`;
//活动报名
const applyActivityUrl = `/activityApi/api/activity/activityApply`;
// 取消报名
const cancelActivityUrl = `/activityApi/api/activity/cancelActivityApply`;
// 我报名的活动详情
const activityDetailUrl = `/activityApi/api/activity/myActivityDetail`;
//我的报名
const myActivityUrl = `/activityApi/api/activity/myActivityList`;
//发布活动共
const publishActivityUrl = `/activityApi/api/activity/postActivity`;
//发布的活动列表

const myPublishActivityUrl = `/activityApi/api/activity/myActivityApplyList`;
// 新接口 参与活动
// const applyActivityUrl = `/activityApi/api/activity/v1/activityApply`;

//个人信息
const userInfoUrl = `/miniApi/user/mini/user-info`;
//场所列表
const areaListUrl = `/activityApi/api/activity/reservationPlaceList`;
export {
  getActivityListUrl,
  getActivityDetailUrl,
  addPeopleUrl,
  addPeopleListUrl,
  addPeopleDetailUrl,
  deletePeopleUrl,
  applyActivityUrl,
  cancelActivityUrl,
  activityDetailUrl,
  myActivityUrl,
  publishActivityUrl,
  myPublishActivityUrl,
  userInfoUrl,
  areaListUrl,
};
